<template>
  <div class="about">
    <h1>This is an about page</h1>
  </div>
</template>

<script>

import Community from '@/services/Community.js'

export default {
  name: 'About',
  props: {
  },
  data (){
    return {
    }
  },
  created() {
    console.log('tt')
    Community.check();

  },
  methods: {
  }
}

</script>